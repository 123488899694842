.Footer {
    display: grid;
    margin: 0 0.5rem;
    padding: 0.5rem 0;
    align-items: flex-end;
    border-top: solid 1px #FFFFFF30;
}

.Footer_text {
    font-size: 0.8rem;
}

@media screen and (min-width: 600px) {
    .Footer {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;
    }
}