.CallToAction {
    display: grid;
    justify-content: center;
    margin: 1rem;
    padding: 1rem 0;
    border-top: solid 1px #FFFFFF30;
    border-bottom: solid 1px #FFFFFF30;
}

.CallToAction_container {
    display: grid;
    gap: 1rem;
    max-width: 1080px;
    width: 100%;
}

.CallToAction_header{
    text-align: left;
    color: #fcdb2d;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.1rem;
}

.CallToAction_text {
    text-align: left;
    letter-spacing: 0.2px;
    font-size: 1.1rem;
    line-height: 1.4rem;
}

.CallToAction_chartContainer {
    display: grid;
    background-color: #26426460;
    border-radius: 5px;
    border: 1px solid #FFFFFF30;
    padding: 0.5rem;
    height: 200px
}

.CallToAction_chartHeader {
    text-align: center;
    font-size: 1rem;
}

.CallToAction_button {
    padding: 0.5rem;
    background-color: #118190;
    border-radius: 5px;
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
}

.CallToAction_button:hover {
    border: solid 1px #FFFFFF;
}

@media screen and (min-width: 850px) {
    .CallToAction_container {
        padding: 2rem;
    }
    .CallToAction_header{
        font-size: 2rem;
    }
    
    .CallToAction_text {
        line-height: 1.5rem;
        font-size: 1.2rem
    }

    .CallToAction_chartContainer {
        padding: 0.5rem;
        height: 300px
    }

    .CallToAction_chartHeader {
        font-size: 1.5rem;
    }
}