.LifterItem {
    display: grid;
    grid-template-columns: 1fr 6fr repeat(4, 3fr);
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    background-color: #26426460;
    backdrop-filter: blur(10px);
    padding: 0.5rem;
}

.LifterItem_text {
    font-size: 0.8rem;
}

.LifterItem_none {
    grid-column: 1/5;
}
