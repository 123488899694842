.HeroBranch_heroContainer {
    display: grid;
}

.HeroBranch_imageContainer {
    display: none;
}

.HeroBranch_registerContainer,
.HeroBranch_rankSearchContainer,
.HeroBranch_loginContainer,
.HeroBranch_userRankContainer {
    width: 100%;
    max-width: 720px;
    z-index: 2;
    justify-self: center;
}   


@media screen and (min-width: 1050px) {
    
    .HeroBranch_heroContainer {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 1080px;
        place-self: center;
        align-items: center;
    } 
    
    .HeroBranch_imageContainer {
        display: flex;
        justify-content: flex-end;
    }

    .HeroBranch_image {
        width: 110%;
        height: auto;
    }
}