.RankSearch {
    gap: 0.5rem;
    padding: 0 1rem;
    display: grid;
}

.RankSearch_form {
    display: grid;
    gap: 0.5rem;
    border: solid 1px #FFFFFF30;
    padding: 1rem 2rem;
    border-radius: 5px;
    background-color: #26426460;
    backdrop-filter: blur(10px);
    width: 100%;
    max-width: 720px;
    justify-self: center;
}

.RankSearch_header {
    font-weight: 400;
}

.RankSearch_formSectionContainer {
    display: grid;
    border-radius: 5px;
}

.RankSearch_label {
    font-size: 1rem;
}

.RankSearch_selectBorder {
    border-radius: 5px;
    background-color: #264264;
}

.RankSearch_numberButton {
    padding: 0.5rem 2rem;
    border: none;
    user-select: none;
}

.RankSearch_numberButton:hover {
    background-color: #FFFFFF30;
}

.RankSearch_buttonSelectContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.RankSearch_setTextContainer {
    display: flex;
}


.RankSearch_numberInput,
.RankSearch_repsInput {
    width: 3rem;
    text-align: center;
    border: none;
    font-size: 1rem;
    background-color: transparent;
}

.RankSearch:focus-visible {
    outline: none;
}

.RankSearch_repsInput:focus-visible {
    outline: none;
}

.RankSearch_text {
    text-align: center;
    font-size: 1rem;
}

.RankSearch_sexButtonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.RankSearch_sexButton {
    padding: 0.5rem 0;
    background-color: #264264;
    backdrop-filter: blur(10px);
    border: solid 1px transparent;
    font-size: 1rem;
}


.RankSearch_submitButton:hover,
.RankSearch_sexButton:hover {
    border: solid 1px #FFFFFF;
}

.RankSearch_sexSelected {
    border: solid 1px #FFFFFF;
}

.RankSearch_submitButton {
    padding: 0.5rem;
    background-color: #118190;
    border-radius: 5px;
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
}

.RankSearch_error {
    text-align: center;
}

@media screen and (min-width: 1050px) {
    .RankSearch_form {
        padding: 2rem 4rem;
        gap: 1rem;
    }
}