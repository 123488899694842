.RegisterForm {
    padding: 0 1rem;
}

.RegisterForm_form {
    display: grid;
    gap: 0.5rem;
    border: solid 1px #FFFFFF30;
    padding: 1rem 2rem;
    border-radius: 5px;
    background-color: #26426460;
    backdrop-filter: blur(10px);
}

.RegisterForm_header {
    text-align: center;
}

.RegisterForm_inputContainer {
    display: grid;
}

.RegisterForm_input {
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #264264;
    backdrop-filter: blur(10px);
    border: none;
    font-size: 1rem;
    color:#FFFFFF;
    width: 100%;
}

.RegisterForm_formSectionContainer {
    display: grid;
    border-radius: 5px;
}

.RegisterForm_label {
    font-size: 1rem;
}

.RegisterForm_selectBorder {
    border-radius: 5px;
    background-color: #264264;
    backdrop-filter: blur(10px);
}

.RegisterForm_numberButton {
    padding: 0.5rem 2rem;
    border: none;
    user-select: none;
}

.RegisterForm_numberButton:hover {
    background-color: #FFFFFF30;
}

.RegisterForm_buttonSelectContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.RegisterForm_setTextContainer {
    display: flex;
}


.RegisterForm_numberInput,
.RegisterForm_repsInput {
    width: 3rem;
    text-align: center;
    border: none;
    font-size: 1rem;
    background-color: transparent;
}

.RegisterForm:focus-visible {
    outline: none;
}

.RegisterForm_repsInput:focus-visible {
    outline: none;
}

.RegisterForm_text {
    text-align: center;
    font-size: 1rem;
}

.RegisterForm_sexButtonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.RegisterForm_sexButton {
    padding: 0.5rem 0;
    background-color: #264264;
    backdrop-filter: blur(10px);
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
}

.RegisterForm_submitButton:hover,
.RegisterForm_sexButton:hover {
    border: solid 1px #FFFFFF;
}

.RegisterForm_sexSelected {
    border: solid 1px #FFFFFF;
}

.RegisterForm_submitButton {
    padding: 0.5rem;
    background-color: #118190;
    border-radius: 5px;
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
}

.RegisterForm_error {
    color: #FFFFFF;
    text-align: center;
}


.RegisterForm_loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.RegisterForm_loadingSvg {
    width: 2rem;
    height: 100%;
}

@media screen and (min-width: 500px) {
    .RegisterForm_input {
        max-width: none
    }
}