.CommunityTable {
    display: grid;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem 0.5rem;
}

.CommunityTable_container {
    display: grid;
    width: 100%;
    max-width: 1080px;
    margin: auto;
}

.CommunityTable_header {
    text-align: left;
    color: #fcdb2d;
    font-weight: 400;
}

.CommunityTable_table {
    display: grid;
    gap: 0.5rem;
}

.CommunityTable_tableHeaders {
    display: grid;
    grid-template-columns:  1fr 6fr repeat(4, 3fr);
    padding: 0.5rem;
    text-align: center;
    text-decoration: underline;
}

.CommunityTable_tableHeaders > p:first-child {
    text-align: left;
}

@media screen and (min-width: 850px) {
    .CommunityTable {
        padding: 2rem;
    }
}
