.LoginForm {
    padding: 0.5rem 1rem;
}

.LoginForm_form {
    display: grid;
    gap: 0.5rem;
    border: solid 1px #FFFFFF30;
    padding: 1rem 2rem;
    border-radius: 5px;
    background-color: #26426460;
    backdrop-filter: blur(10px);
}

.LoginForm_header {
    text-align: center;
}

.LoginForm_inputContainer {
    display: grid;
}

.LoginForm_input {
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #264264;
    backdrop-filter: blur(10px);
    border: none;
    font-size: 1rem;
    color:#FFFFFF;
    width: 100%;
}

.LoginForm_submitButton {
    padding: 0.5rem;
    background-color: #118190;
    border-radius: 5px;
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
}

.LoginForm_submitButton:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.LoginForm_loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoginForm_loadingSvg {
    width: 2rem;
    height: 100%;
}

@media screen and (min-width: 500px) {
    .LoginForm_input {
        max-width: none
    }
}