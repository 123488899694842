*,
*::before,
*::after {
    box-sizing: border-box;
}

body, h1, h2, h3, p, select, input {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
}

button {
    background-color: transparent;
    border: solid 1px grey;
    border-radius: 5px;
    color: #FFFFFF;
}

button:hover, input[type=submit]:hover {
    cursor: pointer;
}

a {
    color: #118190;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}