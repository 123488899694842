.UserRank {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
}

.UserRank_topRankSection {
    border: solid 1px #FFFFFF30;
    width: 80%;
    margin: 0 auto;
    padding: 1rem 2rem;
    border-radius: 5px;
    background-color: #26426460;
    backdrop-filter: blur(10px);
}

.UserRank_header {
    text-align: center;
    font-size: 1.5rem;
}

.UserRank_rankContainer {
    display: grid;
    text-align: center;
}

.UserRank_imageContainer {
    margin-bottom: -25%;
}

.UserRank_image {
    width: 100%;
    height: 75%;
    object-fit: cover;
    justify-self: center;
}

.UserRank_rankTextContainer {
    display: flex;
    place-content: center;
    gap: 0.2rem;
    position: relative;
}

.UserRank_unverified {
    cursor: pointer;
}

.UserRank_popupContainer {
    position: absolute;
    top: -300%;
    background-color: #264264;
    padding: 0.5rem;
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
}

.UserRank_needVerifyContainer {
    position: absolute;
    top: -300%;
    background-color: #264264;
    padding: 0.5rem;
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
}

.UserRank_select {
    border: none;
    outline: none;
    font-size: 1rem;
    background-color: transparent;
}

.UserRank_select > option {
    color: #00171f;
}


.UserRank_text {
    text-align: center;
}

.UserRank_rankBarContainer {
    height: 1rem;
    width: 100%;
    border: solid 1px #FFFFFF30;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

}

.UserRank_rankBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    background-color: #118190;
}

.UserRank_sexWeightContainer {
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    margin-top: 0.5rem;
}

.UserRank_sex{
    display: grid;
    font-size: 0.9rem;
    align-self: center;
}

.UserRank_liftsSection {
    display: flex;
    place-content: center;
    gap: 1rem;
}

.UserRank_liftContainer {
    display: grid;
    text-align: center;
    padding: 0.5rem 1rem;
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    background-color: #26426460;
    backdrop-filter: blur(10px);
}

.UserRank_svgIcon {
    width: 4rem;
    height: auto;
}

.UserRank_svgIcon > path {
    fill: #ffffff;
}

.UserRank_buttonContainer {
    text-align: center;
}

.UserRank_button {
    padding: 0.5rem;
    background-color: #118190;
    border-radius: 5px;
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
}


.UserRank_button:hover {
    border: solid 1px #FFFFFF;
}

/*User Weight Item Styling*/

.UserWeightItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    place-self: center;
    gap: 1rem;
    position: relative;
}

.UserWeightItem_buttonContainer {
    display: flex;
    gap: 0.5rem;
}

.UserWeightItem_button {
    height: auto;
    font-size: 0.9rem;    
    background-color: #118190;
    backdrop-filter: blur(10px);
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    padding: 0.2rem;
}

.UserWeightItem_button:hover {
    border: solid 1px #FFFFFF;
}

.UserWeightItem_input {
    width: 3rem;
    text-align: center;
    place-self: center;
    font-size: 0.9rem;
    border: none;
    border-bottom: solid 1px #FFFFFF;
    background-color: transparent;
}

.UserWeightItem_button:hover {
    cursor: pointer;
    color: #FFFFFF;
}

.UserWeightItem_weightContainer {
    display: grid;
    grid-template-rows: 1fr 1fr;
    font-size: 0.9rem;
    text-align: center;
}

.UserWeightItem_svgIcon {
    width: 4rem;
    height: auto;
}

.UserWeightItem_svgIcon > path {
    stroke: #FFFFFF;
    stroke-width: 2;
}

.UserWeightItem_weightDropDown{
    display: flex;
    gap: 0.2rem;
    align-items: center;
    border: none;
}

.UserWeightItem_weightDropDown:hover > span{
    cursor: pointer;
    text-decoration: underline;
}

.UserWeightItem_scrollTriangle {
    width: 0.5rem;
    fill: #FFFFFF;
}

@media screen and (min-width: 850px) {
    .UserRank_topRankSection {
        width: 80%;
    }

    .UserRank_sex {
        font-size: 1rem;
    }

    .UserWeightItem_weightContainer {
        font-size: 1rem;
    }

    .UserRank_popupContainer {
        top: -200%;
    }
}
