.Navbar {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    z-index: 2;
}

.NavLogo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fcdb2d
}

.NavLogo:hover {
    cursor: pointer;
    opacity: 0.5;
}


.AuthNavButtons,
.NonAuthNavButtons {
    list-style-type: none;
    display: flex;
    column-gap: 1rem;
}

.AuthNavButtons_navbutton,
.NonAuthNavButtons_navbutton {
    padding: 0.2rem 1rem;
    background-color: #118190;
    border-radius: 5px;
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
}


.AuthNavButtons_navbutton:hover,
.NonAuthNavButtons_navbutton:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

@media screen and (min-width: 850px) {
    .NavLogo { 
        font-size: 2rem;
    } 
}